<template>
  <div class="mb-2">
    <v-row v-if="pending">
      <v-col
        class="text-sm-left px-6 py-2"
        :class="{ 'py-8': !compact && $vuetify.breakpoint.mdAndUp }"
      >
        <v-banner :class="compact && ['transparent', 'white--text', 'compact-banner']">
          <span class="headline mb-1 mr-2">
            Statistics pending<span style="letter-spacing: 0.06em !important;">
              <span
                v-for="index in 3"
                :key="index"
                :style="{ opacity: index <= dotCount ? 1 : 0 }"
              >.</span>
            </span>
          </span>
          <br v-if="$vuetify.breakpoint.xsOnly">
          <span class="subtitle-1 metal--text">
            Add detail to get started
          </span>
        </v-banner>
      </v-col>
    </v-row>
    <div
      v-else-if="version && version._id === versionId"
      class="d-flex my-2"
      :class="compact ? ['justify-center'] : ['justify-start']"
    >
      <div
        class="d-flex"
        :style="{ 'width': compact ? '100%' : 'auto', 'max-width': compact ? (`${Math.max($vuetify.breakpoint.width / 3, 500)}px`) : 'auto' }"
        :class="compact ? ['flex-row', 'justify-space-around'] : ['flex-column']"
      >
        <StatItem
          :text="compact ? sequestrationText.condensed : sequestrationText.full.text"
          :color="sequestrationText.full.color"
          :compact="compact"
        >
          <template v-slot:icon>
            <SVGIcon
              :fill="compact ? 'white' : 'primary'"
              :size="compact ? 55 : 35"
            >
              <path d="M16.1 4.1c-.2-1.6-1.5-2.8-3.1-2.8-.5 0-.9.1-1.3.3C11.1.6 10 0 8.7 0 7.6 0 6.6.6 6 1.5c1.2.1 2.4.7 3.1 1.8.4-.2.9-.3 1.3-.3 1.9 0 3.5 1.4 3.9 3.3 1.3.6 2.2 2 2.2 3.5.9-.6 1.5-1.6 1.5-2.8 0-1.3-.8-2.4-1.9-2.9zM9.4 6.7c-.4 0-.7.2-1 .4-.3.3-.4.7-.4 1.1 0 .4.1.7.4 1 .3.3.6.4 1 .4s.7-.2 1-.4c.3-.3.4-.6.4-1s-.2-.7-.4-1c-.3-.3-.6-.5-1-.5z" />
              <path d="M13.9 6.5c-.2-1.8-1.7-3.1-3.5-3.1-.5 0-1 .1-1.5.3-.6-1.2-1.8-1.9-3.2-1.9-2.1 0-3.8 1.7-3.8 3.8v.3C.7 6.7 0 7.9 0 9.4c0 2.3 1.8 4.1 4.1 4.1.5 0 1-.1 1.4-.3.6.8 1.6 1.2 2.7 1.2 1.2 0 2.3-.6 2.9-1.5.4.2.9.3 1.4.3 1.9 0 3.5-1.6 3.5-3.5.1-1.4-.8-2.7-2.1-3.2zM4.4 9.1c.3.3.6.4.9.4.2 0 .4 0 .6-.1.2-.1.4-.2.5-.4l.4.4c-.4.5-.8.8-1.4.8s-1.1-.2-1.5-.6c-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.5c.4-.4.9-.6 1.5-.6s1.1.2 1.5.7l-.4.5c-.2-.2-.4-.3-.5-.4-.2-.1-.4-.1-.6-.1-.4 0-.7.1-1 .4-.3.2-.4.5-.4.9s.1.7.4 1zm6.4.5c-.4.4-.9.6-1.5.6s-1-.2-1.5-.6c-.3-.4-.5-.9-.5-1.4s.2-1.1.6-1.4c.4-.4.9-.6 1.5-.6s1.1.2 1.5.6c.4.4.6.9.6 1.4s-.3 1-.7 1.4zm2.1 2.4h-1.6v-.3l.7-.7c.2-.2.3-.3.3-.4.1-.1.1-.2.1-.3 0-.1 0-.2-.1-.2-.1-.1-.2-.1-.3-.1-.2 0-.3.1-.5.3l-.2-.3c.1-.2.2-.3.3-.3.1-.1.3-.1.5-.1s.4.1.5.2c.2.1.2.3.2.5 0 .1 0 .2-.1.3-.1.1-.2.3-.3.4l-.6.6h1l.1.4z" />
            </SVGIcon>
          </template>
        </StatItem>
        <StatItem
          :text="compact ? yearPositiveText.condensed : yearPositiveText.full.text"
          :color="yearPositiveText.full.color"
          :compact="compact"
        >
          <template v-slot:icon>
            <SVGIcon
              :viewbox-size="30"
              :fill="compact ? 'white' : 'primary'"
              :size="compact ? 45 : 35"
            >
              <path d="M14.6 0C6.5 0 0 6.5 0 14.6s6.6 14.6 14.6 14.6c8.1 0 14.6-6.6 14.6-14.6C29.3 6.5 22.7 0 14.6 0zm5.2 15.2h-4.5v4.9H14v-5H9.5V14H14V9.2h1.2V14h4.5v1.2z" />
            </SVGIcon>
          </template>
        </StatItem>
        <StatItem
          :text="compact ? rankingText.condensed : rankingText.full.text"
          :color="rankingText.full.color"
          :compact="compact"
        >
          <template v-slot:icon>
            <SVGIcon
              :viewbox-size="30"
              :fill="compact ? 'white' : 'primary'"
              :size="compact ? 50 : 35"
            >
              <path d="M26 11.4C26 5.1 20.9 0 14.6 0 8.3 0 3.2 5.1 3.2 11.4c0 6.3 5.1 11.4 11.4 11.4 6.3 0 11.4-5.1 11.4-11.4zm-4.1-1.9l-4.1 3 1.6 4.8c.1.2 0 .4-.2.5-.1.1-.2.1-.3.1s-.2 0-.3-.1l-4.1-3-4.1 3c-.2.1-.4.1-.5 0-.2-.1-.2-.3-.2-.5l1.6-4.8-4.1-3c0-.1-.1-.3 0-.5s.2-.3.4-.3h5l1.6-4.8c.1-.2.2-.3.4-.3s.4.1.4.3l1.6 4.8h5c.2 0 .4.1.4.3s0 .4-.1.5z" />
              <path d="M4.4 18.1L0 25l3.9-.1c.2 0 .5.1.5.3L6 28.8l3.9-6.1c-2.3-.9-4.2-2.5-5.5-4.6zM24.9 18.1c-1.3 2-3.3 3.7-5.5 4.6l3.9 6.1 1.6-3.7c0-.1.1-.2.2-.2h.2l4 .1-4.4-6.9z" />
            </SVGIcon>
          </template>
        </StatItem>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex'
import SVGIcon from '@/components/atoms/SVGIcon'
import StatItem from './StatItem'
import { captureException } from '@sentry/vue'

const { mapGetters: mapVersionGetters, mapActions: mapVersionActions } = createNamespacedHelpers('projects/versions')
const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')

const numToOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export default {
  name: 'ProjectStats',
  components: {
    SVGIcon,
    StatItem
  },
  props: {
    versionId: {
      type: String,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pending: false,
      dotCount: 3,
      interval: null
    }
  },
  computed: {
    ...mapVersionGetters(['version']),
    ...mapProjectGetters(['project']),
    ...mapGlobalGetters(['projectTypes']),
    targetYears () {
      const type = this.projectTypes.find(x => x.name === this.project.type)
      return type ? type.targetYears : ''
    },
    sequestrationText () {
      if (!this.version.stats) {
        return ''
      }
      const emissions = this.version.stats.totals.overall
      let text
      let total = emissions
      let units = 'tonnes'
      if (Math.abs(total) < 1) {
        total = total * 1000
        units = 'kg'
      }
      const displayedValue = `${Math.round(Math.abs(total))} ${units}`
      if (emissions === 0) {
        text = 'Your project will emit the same amount of carbon as it sequesters in its estimated lifespan'
      } else if (emissions > 0) {
        text = `Your project will emit <strong>${displayedValue}</strong> more carbon than it sequesters in its estimated lifespan`
      } else {
        text = `Your project will sequester <strong>${displayedValue}</strong> more carbon than it emits in its estimated lifespan`
      }
      return {
        condensed: {
          value: `${Math.max(Math.round(total * -1), 0)} ${units}`,
          subText: 'carbon sequestered beyond emissions'
        },
        full: {
          text,
          color: emissions <= 0 ? 'var(--v-shamrock-base)' : 'rgb(220, 14, 21)'
        }
      }
    },
    yearPositiveText () {
      if (!this.version.stats) {
        return ''
      }
      if (!this.version.stats.yearPositive) {
        return {
          condensed: { value: 'N/A', subText: 'will not reach positive' },
          full: { text: 'This project does not sequester more carbon per year than it emits, so will never reach climate positive.', color: 'rgb(220, 14, 21)' }
        }
      }
      return {
        condensed: {
          value: this.version.stats.yearsToPositive,
          subText: 'years to positive'
        },
        full: {
          text: `Your project is expected to reach climate positive in <strong> ${this.version.stats.yearsToPositive}</strong> years, in <strong> ${this.version.stats.yearPositive}</strong>.`,
          color: this.version.stats.yearsToPositive < this.targetYears ? 'var(--v-shamrock-base)' : 'rgb(220, 14, 21)'
        }
      }
    },
    rankingText () {
      if (!this.version.stats) {
        return ''
      }
      let rank
      let p = this.version.stats.percentileRanking < 0.5 ? 'upper' : 'lower'
      rank = Math.round((1 - this.version.stats.percentileRanking) * 100, 0).toString()
      rank = numToOrdinal(rank)
      const str = `${p} ${rank}`
      return {
        condensed: {
          value: str,
          subText: 'percentile of projects'
        },
        full: {
          text: `Your positive score is in the <strong> ${str}</strong> percentile of projects in our database.`,
          color: this.version.stats.percentileRanking > 0.5 ? 'var(--v-shamrock-base)' : 'rgb(220, 14, 21)'
        }
      }
    }
  },
  watch: {
    version: {
      immediate: true,
      handler () {
        if (!this.version) {
          return
        }
        if (this.version.stats && this.version.stats.pending) {
          // this is default behaviour for all new projects
          this.pending = this.version.stats.pending
        } else {
          // fallback for versions with no stats as previously
          const hasStats = this.version.stats && this.version.stats.yearByYear && this.version.stats.yearByYear.length > 0
          this.pending = !hasStats
        }
      }
    }
  },
  async created () {
    if (!this.version || this.version._id !== this.versionId) {
      if (!this.versionId) {
        captureException(new Error('No version id provided'))
      }
      await this.fetchVersion({ id: this.versionId })
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      if (this.dotCount < 3) {
        this.dotCount += 1
      } else {
        this.dotCount = 1
      }
    }, 750)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    ...mapVersionActions(['fetchVersion'])
  }
}
</script>

<style lang="scss" scoped>
.compact-banner {
  border-bottom: none;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  span {
    color: #fff !important;
  }
  .v-banner__wrapper {
    border-bottom: none;
  }
}
</style>
