<template>
  <v-dialog
    v-model="internalDialog"
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-icon
        :small="small"
        :color="iconColor"
        v-on="dialog"
        @click="openDialog"
      >
        {{ icon }}
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="text-h6">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <!-- Slot for content -->
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :color="buttonColor"
          text
          @click="closeDialog"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InfoDialog',
  props: {
    // Dialog properties
    maxWidth: {
      type: String,
      default: '300'
    },

    // Icon properties
    icon: {
      type: String,
      default: 'mdi-book-open-page-variant'
    },
    small: {
      type: Boolean,
      default: true
    },
    iconColor: {
      type: String,
      default: 'grey'
    },

    // Content properties
    title: {
      type: String,
      default: 'Information'
    },

    // Button properties
    buttonText: {
      type: String,
      default: 'Close'
    },
    buttonColor: {
      type: String,
      default: 'primary'
    }
  },

  data () {
    return {
      internalDialog: false
    }
  },

  methods: {
    openDialog () {
      this.internalDialog = true
      this.$emit('open')
    },
    closeDialog () {
      this.internalDialog = false
      this.$emit('close')
    }
  }
}
</script>
