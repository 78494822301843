

















import Vue, { PropType } from 'vue'
import { Unit } from 'convert-units'
import numeral from 'numeral'
import { createNamespacedHelpers } from 'vuex'
import { convertProjectSystem, ConvertReturnType } from '@/helpers/unitConversions'
import VueI18n from 'vue-i18n'

const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')

type i18nProps = {
  key: VueI18n.Path;
  values?: VueI18n.Values
}

export default Vue.extend({
  props: {
    i18n: {
      type: Object as PropType<i18nProps>,
      default: undefined
    },
    impactful: {
      type: Boolean,
      required: false,
      default: null
    },
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    fromUnit: {
      type: String,
      default: undefined
    },
    toImperial: {
      type: String,
      default: undefined
    },
    toMetric: {
      type: String,
      default: undefined
    },
    append: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default: '0,0.00'
    },
    color: {
      type: String,
      default: null
    }
  },
  data () {
    return {}
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapProjectGetters(['project']),
    normalizedValue: function (): number {
      return Number(this.value)
    },
    convertedValue: function (): Number | String {
      const value = this.convert()?.value
      if (this.format) {
        return numeral(value).format(this.format)
      }
      return value
    },
    convertedUnit: function (): String | undefined {
      const valueUnit = this.convert()?.unit?.abbr
      return valueUnit
    },
    i18nContext: function (): i18nProps | undefined {
      if (this.i18n) {
        return {
          key: this.i18n.key,
          values: {
            ...this.i18n.values,
            value: this.convertedValue,
            unit: this.convertedUnit
          }
        }
      }
      return undefined
    },
    context: function (): { value: any; unit: any } {
      const unitLPath = `units.symbols.${this.convertedUnit}`
      const unitT = this.$te(unitLPath) ? this.$t(unitLPath) : this.convertedUnit
      return {
        value: this.convertedValue,
        unit: unitT
      }
    }
  },
  methods: {
    convert (): ConvertReturnType {
      const { fromUnit, toMetric, toImperial } = this
      if (fromUnit === undefined || toMetric === undefined || toImperial === undefined) {
        return { value: this.normalizedValue }
      }
      return convertProjectSystem({
        v: this.normalizedValue,
        project: this['project'],
        from: this.fromUnit as Unit,
        toMetric: this.toMetric as Unit,
        toImperial: this.toImperial as Unit
      })
    }
  }
})

