<template>
  <v-row
    v-if="co2Info(totalCo2Kg).delta !== undefined"
    class="pt-1 px-3"
  >
    <v-spacer />
    <slot name="prepend" />
    <div
      class="d-flex"
      :class="$vuetify.breakpoint.xsOnly ? 'flex-column align-end' : 'align-center'"
    >
      <!-- <v-progress-linear
        v-if="false"
        color="red"
        class="mr-2"
        style="width: 50px; height: 8px;"
        indeterminate
      /> -->
      <!-- shipping co2 -->
      <span
        v-if="shippingCo2Kg"
        :style="{ color: co2Info(shippingCo2Kg).color }"
      >
        <strong>{{ co2Info(shippingCo2Kg).value.toLocaleString() }}</strong>
        <span style="white-space: nowrap;">
          kgCO₂e
          <span class="caption metal--text text--darken-2 font-weight-medium">
            (shipping)
          </span>
        </span>
      </span>
      <!-- carbon stored -->
      <v-tooltip
        v-if="storedCo2Kg"
        bottom
        max-width="300"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <span
              class="ml-5"
              :style="{ color: 'var(--v-metal-base)' }"
            >
              <strong>{{ co2Info(storedCo2Kg).value.toLocaleString() }}</strong>
              <span style="white-space: nowrap;">
                kgCO₂e
              </span>
            </span>
          </div>
        </template>
        <span>Carbon Stored</span>
      </v-tooltip>
      <!-- carbon stored -->
      <v-tooltip
        v-if="operationalCo2Kg"
        bottom
        max-width="300"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <span
              class="ml-5"
              :style="{ color: co2Info(operationalCo2Kg).color }"
            >
              <strong>{{ co2Info(operationalCo2Kg).value.toLocaleString() }}</strong>
              <span style="white-space: nowrap;">
                kgCO₂e
              </span>
            </span>
          </div>
        </template>
        <span>Nursery + Transportation Emissions</span>
      </v-tooltip>

      <!-- emissions co2 -->
      <v-tooltip
        bottom
        max-width="300"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <span
              class="ml-5"
              :style="{ color: co2Info(totalCo2Kg || totalWaterUseCO2Impact).color }"
            >
              <strong>{{ co2Info(emissionsCo2Kg || totalWaterUseCO2Impact || totalCo2Kg).value.toLocaleString() }}</strong>
              <span style="white-space: nowrap;">
                kgCO₂e
                <span class="caption">
                  <!-- {{ co2Info(element.emissionsCo2Kg || element.totalCo2Kg).delta > 0 ? 'emitted' : 'sequestered' }} -->
                  <span class="metal--text text--darken-2 font-weight-medium">
                    {{ shippingCo2Kg ? '(in project)' : '' }}
                  </span>
                </span>
              </span>
            </span>
          </div>
        </template>
        <span v-if="isElementType('hydrozone')">
          Water Use Emissions:
        </span>
        <span v-else>
          Element quantity:
        </span>
        <strong>{{ Math.round(totalQuantity).toLocaleString() }}</strong>
        <span> {{ co2MeasuredPer }}</span>
      </v-tooltip>
      <!-- Water Benefits -->
      <v-tooltip
        v-if="isElementType('hydrozone')"
        bottom
        max-width="300"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <span
              class="ml-5"
              :style="{ color: waterInfo(totalWaterUse).color }"
            >
              <SmartValue
                :value="element.totalWaterUse"
                :from-unit="'l'"
                :to-imperial="'gal'"
                :to-metric="'l'"
                :format="'0,0'"
                :impactful="true"
              />
            </span>
          </div>
        </template>
        <span>Project Lifespan Water Use</span>
      </v-tooltip>
    </div>
  </v-row>
</template>

<script>

import colors from '@/schema/colors'
import SmartValue from '@/components/atoms/SmartValue.vue'

export default {
  name: 'ElementImpact',
  components: {
    SmartValue
  },
  props: {
    element: {
      type: Object,
      required: false,
      default: () => null
    },
    elements: {
      type: Array,
      required: false,
      default: () => null
    },
    categories: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {
    shippingCo2Kg () {
      if (this.element) {
        return this.element.shippingCo2Kg
      } else if (this.elements) {
        return this.elements.reduce((acc, element) => acc + (element.shippingCo2Kg || 0), 0)
      } else {
        return this.categories.reduce((acc, category) =>
          acc + category.elements.reduce((acc, element) => acc + (element.shippingCo2Kg || 0), 0), 0)
      }
    },
    storedCo2Kg () {
      if (this.element) {
        return this.element.storedCo2Kg
      } else if (this.elements) {
        return this.elements.reduce((acc, element) => acc + (element.storedCo2Kg || 0), 0)
      } else {
        return this.categories.reduce((acc, category) =>
          acc + category.elements.reduce((acc, element) => acc + (element.storedCo2Kg || 0), 0), 0)
      }
    },
    operationalCo2Kg () {
      if (this.element) {
        return this.element.operationalCo2Kg
      } else if (this.elements) {
        return this.elements.reduce((acc, element) => acc + (element.operationalCo2Kg || 0), 0)
      } else {
        return this.categories.reduce((acc, category) =>
          acc + category.elements.reduce((acc, element) => acc + (element.operationalCo2Kg || 0), 0), 0)
      }
    },
    emissionsCo2Kg () {
      if (this.element) {
        return this.element.emissionsCo2Kg
      } else if (this.elements) {
        return this.elements.reduce((acc, element) => acc + (element.emissionsCo2Kg || 0), 0)
      } else {
        return this.categories.reduce((acc, category) =>
          acc + category.elements.reduce((acc, element) => acc + (element.emissionsCo2Kg || 0), 0), 0)
      }
    },
    totalWaterUseCO2Impact () {
      if (this.element) {
        return this.element.totalWaterUseCO2Impact
      } else if (this.elements) {
        return this.elements.reduce((acc, element) => acc + (element.totalWaterUseCO2Impact || 0), 0)
      } else {
        return this.categories.reduce((acc, category) =>
          acc + category.elements.reduce((acc, element) => acc + (element.totalWaterUseCO2Impact || 0), 0), 0)
      }
    },
    totalQuantity () {
      if (this.element) {
        return this.element.totalQuantity
      } else if (this.elements) {
        return this.elements.reduce((acc, element) => acc + (element.totalQuantity || 0), 0)
      } else {
        return this.categories.reduce((acc, category) =>
          acc + category.elements.reduce((acc, element) => acc + (element.totalQuantity || 0), 0), 0)
      }
    },
    totalCo2Kg () {
      if (this.element) {
        return this.element.totalCo2Kg
      } else if (this.elements) {
        return this.elements.reduce((acc, element) => acc + (element.totalCo2Kg || 0), 0)
      } else {
        return this.categories.reduce((acc, category) =>
          acc + category.elements.reduce((acc, element) => acc + (element.totalCo2Kg || 0), 0), 0)
      }
    },
    waterInfo (value) {
      const delta = value === 0 ? 1 : Math.sign(value)
      return {
        delta,
        value: Math.round(Math.abs(value)),
        color: colors.cpdblue
      }
    },
    co2MeasuredPer () {
      if (this.element) {
        return this.element.co2MeasuredPer
      } else if (this.elements) {
        return this.elements
          .filter((element) => element.co2MeasuredPer)
          .map((element) => element.co2MeasuredPer)?.[0]
      } else {
        return this.categories.flatMap((category) => {
          category.elements
            .filter((element) => element.co2MeasuredPer)
            .map((element) => element.co2MeasuredPer)
        })?.[0]
      }
    }
  },
  methods: {
    isElementType (value) {
      if (this.element) {
        return this.element.type === value
      } else if (this.elements) {
        return this.elements.find((element) => element.type === value)
      } else {
        return this.categories.find((category) =>
          category.elements.find((element) => element.type === value)
        )
      }
    },
    co2Info (value) {
      const delta = value === 0 ? 1 : Math.sign(value)
      // const colorMap = { '0': 'var(--v-primary-base)', '-1': 'var(--v-shamrock-base)', '1': '#dc0e15' }
      const colorMap = { 0: 'var(--v-shamrock-base)', '-1': 'var(--v-shamrock-base)', 1: '#dc0e15' }
      return {
        delta,
        value: Math.round(Math.abs(value)),
        color: colorMap[delta.toString()]
      }
    }
  }
}
</script>
