<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    offset-y
    left
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="onyx"
            icon
            small
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon style="font-size: 21px;">
              mdi-cog
            </v-icon>
          </v-btn>
        </template>
        Options
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-if="showDeleteOption"
        :disabled="disabled"
        @click="$emit('delete-element')"
      >
        <v-progress-circular
          v-if="deleting"
          indeterminate
          class="mr-2"
          color="metal"
        />
        <v-icon
          v-else
          color="red"
          class="mr-2"
          left
        >
          mdi-trash-can-outline
        </v-icon>
        <v-list-item-title>
          <slot name="delete-text">
            Delete element
          </slot>
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="$vuetify.breakpoint.xsOnly && showLayoutSwitch"
        :disabled="disabled"
        @click="$emit('switch-dimensions')"
      >
        <v-progress-circular
          v-if="switchingLayout"
          indeterminate
          class="mr-2"
          color="shamrock"
        />
        <v-icon
          v-else
          color="shamrock"
          class="mr-2"
          left
        >
          mdi-autorenew
        </v-icon>
        <v-list-item-title>Switch dimensions</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="$vuetify.breakpoint.xsOnly"
        :disabled="disabled"
        @click="() => {
          $emit('open-description')
          open = false
        }"
      >
        <v-icon
          color="onyx"
          class="mr-2"
          left
        >
          mdi-book-open-page-variant
        </v-icon>
        <v-list-item-title>View details</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ElementOptions',
  props: {
    deleting: {
      type: Boolean,
      required: true
    },
    showLayoutSwitch: {
      type: Boolean,
      required: false,
      default: false
    },
    switchingLayout: {
      type: Boolean,
      required: false,
      default: undefined
    },
    showDeleteOption: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false
    }
  },
  watch: {
    switchingLayout (val) {
      if (!val) {
        this.open = false
      }
    }
  }
}
</script>
