export function unitsFromEc3String (ec3String, unitMappings) {
  if (!ec3String) return {}

  // Check if it's a compound unit (contains a division symbol)
  if (ec3String.includes('/')) {
    // Split into numerator and denominator parts
    const [numeratorPart, denominatorPart] = ec3String.split('/')
      .map(part => part.trim())

    // Parse the numerator
    const numerator = unitsFromEc3String(numeratorPart, unitMappings)

    // Parse the denominator
    const denominator = unitsFromEc3String(denominatorPart, unitMappings)

    let unit = {}
    if (numerator.unit && denominator.unit) {
      unit = { symbol: `${numerator.unit?.symbol || ''} / ${denominator.unit?.symbol || ''}` }
    }

    return {
      value: numerator?.value,
      unit,
      compound: {
        numerator,
        denominator
      }
    }
  }

  // Handle simple unit case
  const splitString = ec3String.split(' ')
  if (!splitString.length) return {}

  const ec3Unit = splitString[splitString.length - 1]
  const value = Number(splitString.slice(0, -1).join(' '))

  if (isNaN(value)) return {}

  // Normalize unit notation - convert m^3 to m3 to match ec3Symbol format
  const normalizedUnit = ec3Unit.replace(/\^(\d+)/g, '$1')

  // Find matching unit in unitMappings
  const unit = unitMappings.find(x =>
    x.ec3Symbol === normalizedUnit ||
    x.ec3Symbol === ec3Unit ||
    x.symbol === normalizedUnit ||
    x.symbol === ec3Unit
  )

  return {
    value,
    unit,
    compound: false
  }
}
