var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.co2Info(_vm.totalCo2Kg).delta !== undefined)?_c('v-row',{staticClass:"pt-1 px-3"},[_c('v-spacer'),_vm._t("prepend"),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.xsOnly ? 'flex-column align-end' : 'align-center'},[(_vm.shippingCo2Kg)?_c('span',{style:({ color: _vm.co2Info(_vm.shippingCo2Kg).color })},[_c('strong',[_vm._v(_vm._s(_vm.co2Info(_vm.shippingCo2Kg).value.toLocaleString()))]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" kgCO₂e "),_c('span',{staticClass:"caption metal--text text--darken-2 font-weight-medium"},[_vm._v(" (shipping) ")])])]):_vm._e(),(_vm.storedCo2Kg)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',{staticClass:"ml-5",style:({ color: 'var(--v-metal-base)' })},[_c('strong',[_vm._v(_vm._s(_vm.co2Info(_vm.storedCo2Kg).value.toLocaleString()))]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" kgCO₂e ")])])])]}}],null,false,2674874347)},[_c('span',[_vm._v("Carbon Stored")])]):_vm._e(),(_vm.operationalCo2Kg)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',{staticClass:"ml-5",style:({ color: _vm.co2Info(_vm.operationalCo2Kg).color })},[_c('strong',[_vm._v(_vm._s(_vm.co2Info(_vm.operationalCo2Kg).value.toLocaleString()))]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" kgCO₂e ")])])])]}}],null,false,2850530230)},[_c('span',[_vm._v("Nursery + Transportation Emissions")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',{staticClass:"ml-5",style:({ color: _vm.co2Info(_vm.totalCo2Kg || _vm.totalWaterUseCO2Impact).color })},[_c('strong',[_vm._v(_vm._s(_vm.co2Info(_vm.emissionsCo2Kg || _vm.totalWaterUseCO2Impact || _vm.totalCo2Kg).value.toLocaleString()))]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" kgCO₂e "),_c('span',{staticClass:"caption"},[_c('span',{staticClass:"metal--text text--darken-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.shippingCo2Kg ? '(in project)' : '')+" ")])])])])])]}}],null,false,1209603672)},[(_vm.isElementType('hydrozone'))?_c('span',[_vm._v(" Water Use Emissions: ")]):_c('span',[_vm._v(" Element quantity: ")]),_c('strong',[_vm._v(_vm._s(Math.round(_vm.totalQuantity).toLocaleString()))]),_c('span',[_vm._v(" "+_vm._s(_vm.co2MeasuredPer))])]),(_vm.isElementType('hydrozone'))?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',{staticClass:"ml-5",style:({ color: _vm.waterInfo(_vm.totalWaterUse).color })},[_c('SmartValue',{attrs:{"value":_vm.element.totalWaterUse,"from-unit":'l',"to-imperial":'gal',"to-metric":'l',"format":'0,0',"impactful":true}})],1)])]}}],null,false,3905386914)},[_c('span',[_vm._v("Project Lifespan Water Use")])]):_vm._e()],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }