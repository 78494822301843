<template>
  <ValidationObserver
    ref="element"
    v-slot="{ invalid }"
    tag="div"
    class="element-container pb-3"
    style="min-height: 88px;"
    :style="{
      margin: elWidth < 250 ? '0px -3px' : '0px 0'
    }"
  >
    <v-img
      v-if="element.image"
      :src="element.image"
      width="100"
      height="100"
      class="flex-grow-0 mr-3"
      style="border-radius: 4px; margin-top: 5px;"
    />
    <div class="d-flex flex-column flex-grow-1">
      <!-- Header row -->
      <div
        v-if="showHeader"
        class="header my-1 d-flex justify-end"
      >
        <div class="mr-auto">
          <component
            :is="element.webLink ? 'a' : 'span'"
            class="mt-1 subtitle-1 primary--text d-flex align-center"
            :href="element.webLink"
            target="_blank"
            :class="element.webLink && 'shamrock--text font-weight-medium'"
          >
            <BrandLogo
              v-if="element.manufacturer"
              :brand="element.manufacturer"
              size="30"
              class="mr-3"
              style="background: yellow;"
            />
            {{ element.name }}
            <v-icon
              v-if="element.webLink"
              right
              color="shamrock"
              small
            >
              mdi-open-in-new
            </v-icon>
          </component>
        </div>
        <div class="ml-3">
          <v-tooltip
            v-if="element.layouts.length > 1 && $vuetify.breakpoint.smAndUp"
            top
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="disabled"
                class="caption"
                rounded
                color="white shamrock--text"
                elevation="0"
                dark
                small
                :icon="$vuetify.breakpoint.xsOnly"
                v-on="$vuetify.breakpoint.smAndUp ? on : undefined"
                @click="switchLayoutIndex(activeLayout < element.layouts.length - 1 ? activeLayout + 1 : 0)"
              >
                {{ $vuetify.breakpoint.smAndUp ? 'Switch dimensions' : '' }}
                <v-progress-circular
                  v-if="switchingLayout"
                  indeterminate
                  size="15"
                  width="2"
                  class="ml-2"
                  color="shamrock"
                />
                <v-icon
                  v-else
                  right
                  color="shamrock"
                  style="font-size: 20px;"
                >
                  mdi-autorenew
                </v-icon>
              </v-btn>
            </template>
            Switch between available dimension sets
          </v-tooltip>
        </div>
        <div class="ml-3">
          <ElementNotes
            :element="element"
          />
        </div>
        <div class="ml-3">
          <ElementDescriptionV3
            v-if="element.cpdVersion === 3"
            :dialog-visible="showDescription"
            :element="element"
            :co2="co2Info(element.totalCo2Kg)"
            @toggle="showDescription = !showDescription"
          />
          <ElementDescriptionV2
            v-else
            :dialog-visible="showDescription"
            :element="element"
            :co2="co2Info(element.totalCo2Kg)"
            @toggle="showDescription = !showDescription"
          />
        </div>
        <div
          v-if="!element.selectedAssembly || $vuetify.breakpoint.xsOnly"
          class="ml-3"
        >
          <ElementOptions
            :disabled="disabled"
            :deleting="deleting"
            :switching-layout="switchingLayout"
            :show-delete-option="!element.selectedAssembly"
            :show-layout-switch="element.layouts.length > 1"
            @delete-element="$emit('delete-element')"
            @switch-dimensions="switchLayoutIndex(activeLayout < element.layouts.length - 1 ? activeLayout + 1 : 0)"
            @open-description="showDescription = true"
          />
        </div>
      </div>
      <v-layout
        v-if="element.cpdVersion===3"
        wrap
        class="stacked"
      >
        <div
          v-if="allTransportationOptions.length > 0 && element.defaultTransportationOption"
          class="material-list-element"
        >
          <v-autocomplete
            v-model="element.selectedTransportationOption"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
            :items="allTransportationOptions"
            label="Transportation Option"
            @change="value => handleElementOptionsChange('selectedTransportationOption', value)"
          />
        </div>
        <div
          v-if="allTransportationDistanceOptions.length > 0 && element.defaultTransportationDistanceOption"
          class="material-list-element"
        >
          <v-autocomplete
            v-model="element.selectedTransportationDistanceOption"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
            :items="allTransportationDistanceOptions"
            label="Transportation Distance"
            @change="value => handleElementOptionsChange('selectedTransportationDistanceOption', value)"
          />
        </div>
        <div
          v-if="availablePlantingTypes.length > 1"
          class="material-list-element"
        >
          <v-autocomplete
            v-model="element.selectedPlantingType"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
            :items="availablePlantingTypes"
            label="Planting Type"
            @change="value => handleElementOptionsChange('selectedPlantingType', value)"
          />
        </div>
        <div
          v-if="availableIrrigationTypes.length > 1"
          class="material-list-element"
        >
          <v-autocomplete
            v-model="element.selectedIrrigationType"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
            :items="availableIrrigationTypes"
            label="Irrigation Type"
            @change="value => handleElementOptionsChange('selectedIrrigationType', value)"
          />
        </div>
        <div
          v-if="availableNurseries.length > 1 && nurseryHasField(element)"
          class="material-list-element"
        >
          <v-autocomplete
            v-model="element.selectedNursery"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
            :items="availableNurseries"
            label="Nursery"
            @change="value => handleElementOptionsChange('selectedNursery', value)"
          />
        </div>
        <div
          v-if="availableStructuralDiversities.length > 0 && structuralDiversityHasField(element)"
          class="material-list-element"
        >
          <v-autocomplete
            v-model="element.selectedStructuralDiversities"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
            :items="availableStructuralDiversities"
            label="Structural Diversity"
            multiple
            small-chips
            deletable-chips
            @change="value => handleElementOptionsChange('selectedStructuralDiversities', value)"
          />
        </div>
        <v-tooltip
          v-if="element.showPercentNative"
          top
        >
          <template #activator="{ on, attr }">
            <div
              v-if="element.showPercentNative"
              v-bind="attr"
              class="material-list-element"
              v-on="on"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Percent Native"
                rules="max_value:100"
                immediate
              >
                <v-text-field
                  v-model.number="element.percentNative"
                  :disabled="!dimensionsEnabled() || disabled"
                  data-vv-name="name"
                  :label="$t('calculator.controls.nativeSpecies.label')"
                  type="number"
                  suffix="%"
                  :error-messages="errors"
                  :max="100"
                  :hide-details="!errors.length"
                  @change="value => handleElementOptionsChange('percentNative', value)"
                />
              </ValidationProvider>
            </div>
          </template>
          <span>
            {{ $t('calculator.controls.nativeSpecies.tooltip') }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="existingConditionOfNativeEcosystem"
          top
        >
          <template #activator="{ on, attr }">
            <div
              v-bind="attr"
              class="material-list-element"
              v-on="on"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Current Condition"
                immediate
              >
                <v-autocomplete
                  v-model="existingConditionOfNativeEcosystem"
                  :disabled="disabled"
                  item-text="name"
                  item-value="_id"
                  :items="allCurrentConditions.filter((c) => c._id === existingConditionOfNativeEcosystem)"
                  :label="$t('calculator.controls.currentCondition.label')"
                />
              </ValidationProvider>
            </div>
          </template>
          <span>
            {{ $t('calculator.controls.currentCondition.tooltip') }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="element.showTargetCondition"
          top
        >
          <template #activator="{ on, attr }">
            <div
              v-if="element.showTargetCondition"
              v-bind="attr"
              class="material-list-element"
              v-on="on"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Target Condition"
                immediate
              >
                <v-autocomplete
                  v-model="element.targetCondition"
                  :disabled="disabled"
                  item-text="name"
                  item-value="_id"
                  :items="allTargetConditions"
                  :label="$t('calculator.controls.targetCondition.label')"
                  @change="value => handleElementOptionsChange('targetCondition', value)"
                />
              </ValidationProvider>
            </div>
          </template>
          <span>
            {{ $t('calculator.controls.targetCondition.tooltip') }}
          </span>
        </v-tooltip>
        <div
          v-if="availableMaterials.length > 1 && materialHasField(element)"
          class="material-list-element"
        >
          <v-autocomplete
            v-model="element.selectedMaterial"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
            :items="availableMaterials"
            label="Material"
            @change="value => handleElementOptionsChange('selectedMaterial', value)"
          />
        </div>
        <v-tooltip
          v-if="element.type === 'material' && element.showReplacements"
          top
        >
          <template #activator="{ on, attr }">
            <div
              class="material-list-element"
              v-bind="attr"
              v-on="on"
            >
              <v-text-field
                v-model="element.replacements"
                :disabled="!dimensionsEnabled() || disabled"
                data-vv-name="name"
                hide-details
                label="Replacement Over 60 YRS."
                type="number"
                placeholder="Replacement Over 60 YRS."
                @change="value => handleElementOptionsChange('replacements', value)"
              />
            </div>
          </template>
          <span>
            # of element replacements during project 60yr lifespan, <br>
            not including initial installation of element. <br>
            Typical replacement # shown for element specified.
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="element.type === 'plant' && element.percentOfCover !== undefined"
          top
        >
          <template #activator="{ on, attr }">
            <div
              class="material-list-element"
              v-bind="attr"
              v-on="on"
            >
              <v-text-field
                v-model="element.percentOfCover"
                :disabled="!dimensionsEnabled() || disabled"
                data-vv-name="name"
                label="Perennials: Percent of Cover"
                type="number"
                hide-details
                placeholder="Perennials: Percent of Cover"
                @change="value => handleElementOptionsChange('percentOfCover', value)"
              />
            </div>
          </template>
          <span>
            Perennials: Percent of Cover
          </span>
        </v-tooltip>
      </v-layout>
      <div
        v-if="showDimensionList"
        class="dimension-list"
        :style="{display: showInColumns ? 'block' : 'flex'}"
      >
        <div
          v-for="(dimension, index) in element.layouts[activeLayout].dimensions"
          :key="index"
          class="d-flex align-center flex-grow-1 flex-shrink: 1"
          style="flex: 1 1 100px !important"
        >
          <div
            v-if="dimension.editable === false && !dimension.isManualMode"
            class="dimension flex-grow-1"
          >
            <v-tooltip
              top
              max-width="300"
            >
              <template #activator="{ on }">
                <div>
                  <span
                    class="metal--text text--darken-2 d-block"
                    style="font-size: 16px; transform: scale(0.9); transform-origin: left;"
                  >
                    {{ dimension.label }}
                  </span>
                  <div class="d-flex align-center">
                    <div
                      style="border-bottom: 1px solid #777; padding: 5px 3px; background: #f6f6f6;"
                      class="flex-grow-1 d-flex justify-space-between"
                      v-on="on"
                    >
                      <span>{{ dimension.value || '0' }}</span>
                      <span>{{ dimension.units[systemOfMeasurement].symbol }}</span>
                    </div>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          :disabled="disabled"
                          class="ml-1"
                          icon
                          v-on="on"
                          @click="toggleManualMode(index, true)"
                        >
                          <v-icon>mdi-table-edit</v-icon>
                        </v-btn>
                      </template>
                      Edit value
                    </v-tooltip>
                  </div>
                </div>
              </template>
              This is a fixed value dimension calculated from the project's total
              {{ Array.from(new Set(dimension.fixedValueFormula.match(/(\$[a-z_]*)(?![a-z])/gi))).join(', ').replace(/_/g, ' ').replace(/\$/g, '') }}.
              Click the pencil icon to input manually.
            </v-tooltip>
          </div>
          <div
            v-else
            class="dimension"
            :class="(dimension.missingFromFormula && 'missing')"
            :style="{
              'flex-grow': compactDims[index] ? 1 : 2
            }"
          >
            <div class="header d-flex">
              <div class="name d-flex align-center">
                <span>
                  {{ dimension.label }}
                </span>
              </div>
            </div>
            <TextFieldAndSlider
              :key="activeLayout"
              :disabled="!dimensionsEnabled() || disabled || invalid"
              :initial-value="needsMMConversion(dimension) ? dimension.value * 10 : dimension.value"
              :enable-zero-alerts="isMissingInputs && enableZeroAlerts && co2Info(element.totalCo2Kg).delta === 0"
              :min="getValueRange(dimension)[0]"
              :max="getValueRange(dimension)[1]"
              :step="needsMMConversion(dimension) ? 10 : 1"
              :suffix="needsMMConversion(dimension) ? 'mm' : dimension.units[systemOfMeasurement].symbol"
              :icon="iconFromType[dimension.type]"
              :show-slider="!compactDims[index]"
              @change="value => handleDimensionValueChange(index, needsMMConversion(dimension) ? value / 10 : value)"
            />
          </div>
          <v-tooltip
            v-if="dimension.editable === false && dimension.isManualMode"
            top
          >
            <template #activator="{ on }">
              <v-btn
                :disabled="disabled"
                class="ml-1 mt-5"
                icon
                v-on="on"
                @click="toggleManualMode(index, false)"
              >
                <v-icon>mdi-auto-fix</v-icon>
              </v-btn>
            </template>
            Calculate for me
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="co2Info(element.totalCo2Kg).delta === 0 && isMissingInputs"
        class="missing-data-message d-flex align-center"
        :class="enableZeroAlerts ? 'shamrock--text' : 'metal--text'"
      >
        <v-icon
          style="font-size: 19px;"
          :color="enableZeroAlerts ? 'shamrock' : 'metal'"
          class="mr-1"
        >
          mdi-alert-circle-outline
        </v-icon>
        Enter missing values to calculate CO₂e<span class="ml-1"> totals</span>.
      </div>
      <ElementImpact :element="element" />
    </div>
  </ValidationObserver>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'

import { iconFromType } from '@/schema/units'
import { squareMetresToSquareFeet } from '@/helpers/unitConversions'

import TextFieldAndSlider from '@/components/atoms/TextFieldAndSlider'

import ElementOptions from './ElementOptions'
import ElementDescriptionV2 from './v2/ElementDescriptionV2.vue'
import ElementDescriptionV3 from './v3/ElementDescriptionV3.vue'
import ElementNotes from './ElementNotes'

import BrandLogo from '@/components/atoms/BrandLogo'

import { elementTypeHasField as materialHasField } from '../../../views/admin/materials/constants'
import { elementTypeHasField as ecosystemHasField } from '../../../views/admin/ecosystems/constants'
import { elementTypeHasField as nurseryHasField } from '../../../views/admin/nurseries/constants'

import { elementTypeHasField as irrigationTypeHasField } from '../../../views/admin/irrigationTypes/constants'
import { elementTypeHasField as structuralDiversityHasField } from '../../../views/admin/structuralDiversities/constants'
import { elementTypeHasField as plantingTypeHasField } from '../../../views/admin/plantingTypes/constants'
import { elementTypeHasField as percentNativeHasField, allTargetConditions, allCurrentConditions } from '../../../views/admin/percentNative/constants'

import { createNamespacedHelpers, mapActions as mapGlobalActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SmartValue from '@/components/atoms/SmartValue.vue'
import ElementImpact from '@/components/application/element/ElementImpact.vue'
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('elements')
const { mapGetters: mapProjectGetters, mapActions: mapProjectActions } = createNamespacedHelpers('projects')
const { mapGetters: mapListGetters } = createNamespacedHelpers('lists')
const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')

export default {
  name: 'Element',
  components: {
    ElementImpact,
    SmartValue,
    TextFieldAndSlider,
    ElementOptions,
    ElementDescriptionV2,
    ElementDescriptionV3,
    ElementNotes,
    ValidationObserver,
    ValidationProvider,
    BrandLogo
  },
  mixins: [ clickaway ],
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    variables: {
      type: Array,
      default: () => []
    },
    requestedLayout: {
      type: Number,
      default: undefined
    },
    element: {
      type: Object,
      default: null
    },
    enableZeroAlerts: {
      type: Boolean,
      default: false
    },
    deleting: {
      type: Boolean,
      default: false
    },
    switchingLayout: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      elWidth: null,
      iconFromType,
      editingName: false,
      editingLabel: false,
      newElementName: '',
      newLabelName: '',
      showDimensionList: true,
      showValueTypeTooltip: false,
      showDescription: false,
      newElementDescription: '',
      disabled: false,
      editingLayoutFormula: false,
      customUnit: {
        showForm: false,
        units: null,
        ref: null,
        dimIndex: null
      },
      co2Loading: false,
      showInColumns: true,
      isMissingInputs: false,
      // used for the Material and Transportation dropdowns.
      availableIrrigationTypes: [],
      availableStructuralDiversities: [],
      availableMaterials: [],
      availableNurseries: [],
      availablePlantingTypes: [],
      allTargetConditions: allTargetConditions,
      allCurrentConditions: allCurrentConditions,
      availableTransportationOptions: [],
      availableTransportationDistanceOptions: []
    }
  },
  computed: {
    ...mapGetters(['systemOfMeasurement']),
    ...mapListGetters([
      'allIrrigationTypes', 'allStructuralDiversities', 'allMaterials', 'allNurseries', 'allPlantingTypes',
      'allEcosystem', 'allEcosystemCategories', 'allTransportationOptions', 'allTransportationDistanceOptions']),
    ...mapProjectGetters(['project']),
    ...mapVersionGetters(['statsLoading', 'formDisabled']),
    ...mapState({
      stateActiveLayout: state => state.activeLayout
    }),
    existingConditionOfNativeEcosystem () {
      if (!this.element.carbonConscienceIds) return null
      return (this.project.existingBiodiversitySiteConditions.ecosystems || [])
        ?.find((ecosystem) => ecosystem.active && this.element.carbonConscienceIds.includes(ecosystem.cpdId))
        ?.condition
    },
    projectArea () {
      if (this.systemOfMeasurement === 'metric') {
        return this.project.location.area
      } else {
        return squareMetresToSquareFeet(this.project.location.area)
      }
    },
    elIsCompact () {
      if (this.showInColumns) return false
      if (this.element.layouts[this.activeLayout].dimensions.length > 2 && this.elWidth < 450) return true
      if (this.element.layouts[this.activeLayout].dimensions.length > 1 && this.elWidth < 300) return true
      return false
    },
    compactDims () {
      const dims = this.element.layouts[this.activeLayout].dimensions
      return dims.map((dimension, index) => {
        const getAdjustedBreakpoint = (e) => {
          if (this.element.image) {
            e += 100
          }
          return e
        }
        if (!this.elWidth) {
          return false
        }
        if (dims.length < 2 && this.elWidth > getAdjustedBreakpoint(300)) {
          // if less than two dimensions and element width is more than 300px no dims should be compact
          return false
        }
        if (dims.length > 2) {
          // if more than two dimensions, all should be compact
          return true
        }
        switch (index) {
          case 0:
            return this.elWidth < getAdjustedBreakpoint(500)
          case 1:
            return this.elWidth < getAdjustedBreakpoint(700)
          default:
            return false
        }
      })
    },
    activeLayout: {
      get () {
        if (this.requestedLayout !== undefined) {
          return this.requestedLayout
        } else {
          return this.stateActiveLayout
        }
      },
      set (index) {
        this.$emit('layout-changed', index)
      }
    }
  },
  watch: {
    formDisabled (val) {
      this.disabled = val
    },
    co2 (val) {
      const values = this.element.layouts[this.activeLayout].dimensions.map(dim => dim.value)
      const hasAtLeastOneNonZeroValue = values.some(e => e)
      this.isMissingInputs = hasAtLeastOneNonZeroValue && values.includes(0)
    },
    editingName (val) {
      if (val) {
        setTimeout(() => {
          this.$refs['title-field'].focus()
        }, 100)
      }
    },
    requestedLayout (val) {
      if (val) {
        this.activeLayout = val
      }
    },
    'element.totalCo2Kg': function () {
      this.co2Loading = false
    },
    elWidth () {
      this.getShowInColumns()
    },
    element () {
      this.getShowInColumns()
    },
    activeLayout () {
      this.getShowInColumns()
    }
  },
  async created () {
    this.availableIrrigationTypes = this.allIrrigationTypes.filter((option) => {
      return this.element?.irrigationTypes?.includes(option._id)
    })

    if (this.availableIrrigationTypes.length === 1) {
      this.element.selectedIrrigationType = this.availableIrrigationTypes[0]
    }

    this.availableStructuralDiversities = this.allStructuralDiversities.filter((option) => {
      return this.element?.structuralDiversities?.includes(option._id)
    })

    this.availableMaterials = this.allMaterials.filter((option) => {
      return this.element?.materials?.includes(option._id) || this.element?.materialCategories?.includes(option.materialCategory)
    })

    if (this.availableMaterials.length === 1) {
      this.element.selectedMaterial = this.availableMaterials[0]
    }

    this.availableNurseries = this.allNurseries.filter((option) => {
      return this.element?.nurseries?.includes(option._id)
    })
    if (this.availableNurseries.length === 1) {
      this.element.selectedNursery = this.availableNurseries[0]
    }

    this.availablePlantingTypes = this.allPlantingTypes.filter((option) => {
      return this.element?.plantingTypes?.includes(option._id)
    })

    if (this.availablePlantingTypes.length === 1) {
      this.element.selectedPlantingType = this.availablePlantingTypes[0]
    }

    this.newElementName = this.element.name
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.setWidth()
        this.getShowInColumns()
        window.addEventListener('resize', this.setWidth)
      })
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.setWidth)
  },
  methods: {
    ...mapMutations(['setDescription', 'setFormulaForLayout', 'setActiveLayout']),
    ...mapActions(['createLayout', 'createDimension', 'deleteDimension', 'updateDimensionUnit', 'updateDimensionLabel']),
    ...mapProjectActions(['getExistingConditionOfNativeEcosystem']),
    ...mapGlobalActions([]),
    dimensionsEnabled () {
      return (this.validMaterial() && this.validTransportation() &&
        this.validNursery() && this.validIrrigationType() &&
        this.validStructuralDiversity() &&
        this.validPercentNative() &&
        this.element.cpdVersion === 3) || this.element.cpdVersion !== 3
    },
    validNursery () {
      if (!this.element.nurseries?.length > 0 || !nurseryHasField(this.element)) return true
      return !!this.element.selectedNursery
    },
    validMaterial () {
      if (!this.element.materials?.length > 0 || !materialHasField(this.element)) return true
      return !!this.element.selectedMaterial
    },
    validTransportation () {
      if (!this.allTransportationOptions.length > 0 || !this.defaultTransportationOption) return true
      return this.element.selectedTransportationDistanceOption && this.element.selectedTransportationOption
    },
    validIrrigationType () {
      if (!this.element.irrigationTypes?.length > 0 || !irrigationTypeHasField(this.element)) return true
      return !!this.element.selectedIrrigationType
    },
    validStructuralDiversity () {
      if (!this.element.structuralDiversities?.length > 0 || !structuralDiversityHasField(this.element)) return true
      return !!this.element.selectedStructuralDiversities
    },
    validPlantingType () {
      if (!this.element.plantingType?.length > 0 || !plantingTypeHasField(this.element)) return true
      return !!this.element.selectedPlantingType
    },
    validPercentNative () {
      if (!this.element.percentNative?.length > 0 || !percentNativeHasField(this.element)) return true
      return !!this.element.percentNative
    },
    co2Info (value) {
      const delta = value === 0 ? 1 : Math.sign(value)
      // const colorMap = { '0': 'var(--v-primary-base)', '-1': 'var(--v-shamrock-base)', '1': '#dc0e15' }
      const colorMap = { 0: 'var(--v-shamrock-base)', '-1': 'var(--v-shamrock-base)', 1: '#dc0e15' }
      return {
        delta,
        value: Math.round(Math.abs(value)),
        color: colorMap[delta.toString()]
      }
    },
    getShowInColumns () {
      if (this.element.layouts[this.activeLayout].dimensions.length <= 2) {
        this.showInColumns = false
      } else if (this.element.layouts[this.activeLayout].dimensions.length > 2 && this.elWidth < 500) {
        this.showInColumns = true
      } else if (this.element.layouts[this.activeLayout].dimensions.length > 2 && this.elWidth < 330) {
        this.showInColumns = true
      } else {
        this.showInColumns = false
      }
    },
    async handleChange (callback) {
      if (await this.$refs.element.validate()) {
        callback()
      }
    },
    async handleElementOptionsChange (option, value) {
      this.handleChange(() => {
        this.$emit('update-element-options', { option, value })
        this.co2Loading = true
      })
    },
    async handleDimensionValueChange (index, value) {
      this.handleChange(() => {
        this.$emit('update-dimension-value', { activeLayout: this.activeLayout, index, value })
        this.co2Loading = true
      })
    },
    setWidth () {
      const rect = this.$refs.element.$el.getBoundingClientRect()
      this.elWidth = rect.right - rect.left
    },
    switchLayoutIndex (i) {
      this.showDimensionList = false
      this.activeLayout = i
      this.showDimensionList = true
    },
    toggleManualMode (index, newValue) {
      this.$emit('toggle-dimension-manual-mode', { index, isManualMode: newValue })
    },
    needsMMConversion (dimension) {
      // if metric and small units for project are 'mm', do conversion from/to cm on client side
      return dimension.units[this.systemOfMeasurement].symbol === 'cm' && this.project.smallUnits === 'mm'
    },
    getValueRange (dimension) {
      if (dimension.type === 'area') {
        return [0, this.projectArea]
      }
      let ret = dimension.valueRange || [0, 100]
      if (this.needsMMConversion(dimension)) {
        ret = ret.map((v) => v * 10)
      }
      return ret
    },
    ecosystemHasField,
    nurseryHasField,
    structuralDiversityHasField,
    materialHasField
  }
}
</script>

<style lang="scss">

  .element-container {
    margin-bottom: 20px;
    padding: 5px 13px;
    border: 2px solid rgba(0,0,0,0);
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    background: #f5f6f5;
    position: relative;
    .missing-data-message {
      font-size: 14px;
      color: #282020;
      margin-left: auto;
      margin-top: 5px;
    }
    .header {
      .v-text-field__slot {
        font-size: 0.875rem !important;
        font-family: "Roboto", sans-serif !important;
        font-weight: 500;
      }
      .layout-pagination {
        margin-right: 5px;
      }
    }
    .stacked {
      .material-list-element {
        display: inline !important;
        width: 50%;
        height:fit-content;
        padding: 0 6px 0 0 !important;
      }
    }
    .dimension-list {
      align-items: center;
      margin-top: -5px;
      flex-grow: 1;
      flex-wrap: wrap;
      gap: 15px;
      .dimension {
        width: 100%;
        padding: 7px 0 9px 0;
        flex-basis: 0;
        position: relative;
        &.missing {
          &::after {
            border-bottom: 5px solid var(--v-error-base);
            position: absolute; content: "";
            left: 29px; top: 90%; right: 0;
          }
        }
        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: -10px;
          .name {
            transform: scale(0.9) translateY(3px);
            transform-origin: left;
            white-space: nowrap;
            margin-bottom: 6px;
            // font-size: 15px;
            color: var(--v-metal-darken2);
          }
        }
      }
    }
  }
</style>
