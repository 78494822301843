var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"onyx","icon":"","small":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',{staticStyle:{"font-size":"21px"}},[_vm._v(" mdi-cog ")])],1)]}}],null,true)},[_vm._v(" Options ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-list',[(_vm.showDeleteOption)?_c('v-list-item',{attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('delete-element')}}},[(_vm.deleting)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"metal"}}):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red","left":""}},[_vm._v(" mdi-trash-can-outline ")]),_c('v-list-item-title',[_vm._t("delete-text",function(){return [_vm._v(" Delete element ")]})],2)],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly && _vm.showLayoutSwitch)?_c('v-list-item',{attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('switch-dimensions')}}},[(_vm.switchingLayout)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"shamrock"}}):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"shamrock","left":""}},[_vm._v(" mdi-autorenew ")]),_c('v-list-item-title',[_vm._v("Switch dimensions")])],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-list-item',{attrs:{"disabled":_vm.disabled},on:{"click":function () {
        _vm.$emit('open-description')
        _vm.open = false
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"onyx","left":""}},[_vm._v(" mdi-book-open-page-variant ")]),_c('v-list-item-title',[_vm._v("View details")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }